import React, {
    ReactNode,
} from 'react'
import classes from './Typography.style'

export type TVariant = 'headline4' | 'headline5' | 'subtitle1' | 'subtitle2' | 'body2' | 'label'
type Props = {
    variant?: TVariant
    children: ReactNode
    className?: string
}

function Typography({
    className, children, variant = 'headline5',
}: Props): JSX.Element {
    return (
        <div
            className={className}
            css={classes.root({
                variant,
            })}
        >
            {children}
        </div>
    )
}

export default Typography
