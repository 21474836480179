import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        position: relative;
        height: 100%;
    `,
    webCamContainer: css`
        position:absolute;
        min-width:100%;
        min-height:100%;
        background-color: black;
    `,
    buttonContainer: css`
        display: flex;
        position: absolute;
        justify-content: space-between;
        left: 0px;
        bottom: 0px;
        width: 100%;
    `,
    backBtn: css`
        color: white;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px; /* 114.286% */
        letter-spacing: 1.25px;
        text-transform: uppercase;
        width: 140px;
        text-align: center;
        align-self: center;
    `,
    takePhotoBtnCnt: ({
        disabled,
    }: { disabled?: boolean }) => {
        return [
            css`
              width: 95px;
              height: 95px;
              border-radius: 50%;
              border: 1px solid white;
              padding: 8px;
              margin-bottom: 16px;
            `,
            disabled === true
            && css`
                  border-color: #656565;
                `,
        ]
    },
    takePhotoBtn: ({
        disabled,
    }: { disabled?: boolean }) => {
        return [
            css`
                   width: 95px;
                   height: 95px;
                   border-radius: 50%;
                   background-color: white;
            `,
            disabled === true
            && css`
                  background-color: #656565;
                  cursor: not-allowed;
                `,
        ]
    },
    proceedBtnCn: css`
        color: white;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        width: 140px;
        text-align: center;
        align-self: center;
    `,
    proceedBtn: css`
        padding-right: 10px;
        padding-left: 10px;
    `,
    loaderWrapper: css`
        position: absolute;
        top: 50vh;
        left: 50vw;
    `,
    loader: css`
        color: white;
    `,
}

export default classes
