import React, {
    ReactNode,
} from 'react'
import classes from './Layout.style'

type Props = {
    children: ReactNode
    className?: string
    header?: ReactNode
    footer?: ReactNode
}

function Layout({
    children, header, footer, className,
}: Props): JSX.Element {
    return (
        <div
            className={className}
            css={classes.root}
        >
            {header && <div css={classes.header}>{header}</div>}
            <div css={classes.content}>{children}</div>
            {footer && (
                <div css={classes.footerCnt}>
                    <div css={classes.footer}>{footer}</div>
                </div>
            )}
        </div>
    )
}

export default Layout
