import React, {
    useCallback, useState,
} from 'react'

import Form from '@src/shared-components/Form'
import Typography from '@src/shared-components/Typography'
import useForm from '@src/hooks/useForm'
import FormSelect
    from '@src/shared-components/Select/FormSelect'
import {
    ISelectOption,
} from '@src/shared-components/Select/Select'
import {
    ILocationAreaModel,
} from '@src/api/hooks/useGetLocationArea'
import {
    ILocationModel,
} from '@src/api/hooks/useGetGatewayById'
import consoleService from '@src/services/consoleService'
import CheckSvg from '@src/assets/check_blue.svg'
import useT from '@src/hooks/useT/useT'
import FormError from '@src/shared-components/FormError'
import InstallGatewayLayout from '@src/shared-components/InstallGatewayLayout'
import FormAutoComplete from '@src/shared-components/AutoComplete'

import classes from './GatewayDetected.style'

export type FormProps = {
    location: string
    area: string
}

type Props = {
    onNextClick: (props: FormProps) => void
    onBackClick: () => void
    gatewayNumber?: string
    onAreaChange: (val?: ILocationAreaModel) => void
    onAirportChange: (val?: ILocationModel) => void
    locationOptions?: ISelectOption<ILocationModel>[]
    areaOptions?: ISelectOption<ILocationAreaModel>[]
    selectedLocationId: string
    selectedAreaId: string
    locationEnabled: boolean
    setLocationSearchValue: (val: string) => void
}

function GatewayDetected({
    onAreaChange,
    onAirportChange,
    locationOptions,
    areaOptions,
    gatewayNumber,
    selectedAreaId,
    selectedLocationId,
    onNextClick,
    onBackClick,
    locationEnabled,
    setLocationSearchValue,
}: Props): JSX.Element {
    const [
        showErrors,
        setShowErrors,
    ] = useState(false)
    const {
        t,
    } = useT()
    const form = useForm<FormProps>({
        initialValues: {
            location: selectedLocationId,
            area: selectedAreaId,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            consoleService.log('submit', values)
        },
    })

    const handleNextClick = useCallback(() => {
        if (form.values.location && form.values.area) {
            onNextClick(form.values)
        } else {
            setShowErrors(true)
        }
    }, [
        form.values,
        onNextClick,
    ])

    const handleAreaChange = useCallback(
        (_name: string, value: string) => {
            const option = areaOptions?.find((l) => {
                return l.value === value
            })

            onAreaChange(option?.data)
        },
        [
            areaOptions,
            onAreaChange,
        ],
    )

    const handleAirportChange = useCallback(
        (_name: string, value: string) => {
            const option = locationOptions?.find((l) => {
                return l.value === value
            })

            onAirportChange(option?.data)
        },
        [
            locationOptions,
            onAirportChange,
        ],
    )

    const renderFormError = () => {
        return <FormError>{t('THIS_FIELD_IS_REQUIRED')}</FormError>
    }

    return (
        <Form
            form={form}
            css={classes.form}
        >
            <InstallGatewayLayout
                nextButtonText={t('NEXT')}
                onBackClick={onBackClick}
                onNextClick={handleNextClick}
            >
                <center>
                    <Typography variant="headline5">
                        <img
                            alt="Check"
                            src={CheckSvg}
                            css={classes.checkIcon}
                        />
                        {t('CODE_IDENTIFIED')}
                    </Typography>
                    <Typography
                        variant="headline4"
                        css={classes.code}
                    >
                        {gatewayNumber}
                    </Typography>
                </center>

                <div css={classes.formContent}>
                    <Typography
                        variant="subtitle2"
                        css={{
                            paddingBottom: '16px',
                        }}
                    >
                        {t('GATEWAY_DETECTED_SUBTITLE')}
                    </Typography>
                    { locationEnabled && (
                        <FormAutoComplete<FormProps>
                            onChange={handleAirportChange}
                            options={locationOptions || []}
                            name="location"
                            label={t('AIRPORT')}
                            helperText={showErrors && !form.values.location && renderFormError()}
                            applySearchValue={setLocationSearchValue}
                        />
                    )}
                    { !locationEnabled && (
                        <FormSelect<FormProps>
                            options={locationOptions || []}
                            name="location"
                            label={t('AIRPORT')}
                            helperText={showErrors && !form.values.location && renderFormError()}
                            disabled
                        />
                    )}
                    <FormSelect<FormProps>
                        allowEmpty
                        onChange={handleAreaChange}
                        options={areaOptions || []}
                        name="area"
                        label={t('AREA')}
                        helperText={showErrors && !form.values.area && renderFormError()}
                    />
                </div>
            </InstallGatewayLayout>
        </Form>
    )
}

export default GatewayDetected
