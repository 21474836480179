import {
    UseQueryResult, useQuery,
} from 'react-query'
import axios from 'axios'
import {
    UseQueryOptions,
} from '@src/utils/dataTypes'
import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from './useGetAxiosConfig'

export interface ILocationModel {
    id: number
    locationName: string
    countryName: string
    iataCode: string
    city: string
}

export interface IGatewayByIdResponse {
    id: number
    location: {
        id: number
        iataCode: string
        countryName: string
        city: string
        locationName: string
    }
}

const useGetGatewayById = (id: string, options: UseQueryOptions):
UseQueryResult<IGatewayByIdResponse, unknown> => {
    const config = useGetAxiosConfig()

    return useQuery<IGatewayByIdResponse, unknown>(
        `useGetGatewayById-${id}`,
        () => {
            return axios.get(API_URLS.getGatewayByIdUrl(id), config).then((data) => {
                return data.data
            })
        },
        options,
    )
}

export default useGetGatewayById
