import ReactWebcam from 'react-webcam'
import React, {
    useCallback,
    useMemo,
    useRef, useState,
} from 'react'
import {
    CircularProgress,
} from '@mui/material'

import useT from '@src/hooks/useT/useT'
import useEnumerateDevices from '@src/hooks/useEnumerateDevices'
import useCameraResolutionConstrains from '@src/hooks/useCameraResolutionConstrains/useCameraResolutionConstrains'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'
import useTimeout from '@src/hooks/useTimeout/useTimeout'

import classes from './WebCam.style'

type Props = {
    onCaptured: (e: { url: string }) => void,
    onBackClick: () => void,
    onProceedClick: () => void,
};

function WebCam({
    onCaptured: onTakeClick,
    onBackClick,
    onProceedClick,
}: Props) {
    const [
        cameraReady,
        setCameraReady,
    ] = useState(false)
    const [
        showLoader,
        setShowLoader,
    ] = useState(false)
    const {
        timeout,
    } = useTimeout()
    const {
        t,
    } = useT()
    const webcamRef = useRef<ReactWebcam>(null)
    const device = useEnumerateDevices()
    const {
        photos,
    } = usePhotoContext()

    const proceedAllowed = useMemo(() => {
        return photos.length >= 3
    }, [photos])

    const takePhotoAllowed = useMemo(() => {
        return photos.length < 5
    }, [photos])

    const hideLoader = useCallback(() => {
        setShowLoader(false)
        setCameraReady(true)
    }, [])

    const handleCaptureClick = useCallback(() => {
        if (takePhotoAllowed && webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot()

            if (imageSrc) {
                setShowLoader(true)
                onTakeClick({
                    url: imageSrc,
                })
                setCameraReady(false)
                timeout(hideLoader, 500)
            }
        }
    }, [
        webcamRef,
        onTakeClick,
        takePhotoAllowed,
        hideLoader,
        timeout,
    ])

    const {
        videoResolutionConstrains,
    } = useCameraResolutionConstrains()
    const videoConstraints = useMemo(() => {
        const constraints: MediaTrackConstraints = {
            frameRate: {
                max: 30,
            },
            ...videoResolutionConstrains.stream,
            facingMode: 'environment',
        }

        if (device && device.facingMode) {
            constraints.facingMode = device.facingMode
        }

        return constraints
    }, [
        device,
        videoResolutionConstrains.stream,
    ])

    const onUserMedia = useCallback(() => {
        setCameraReady(true)
    }, [])

    const cameraButtonDisabled = useMemo(() => {
        return !takePhotoAllowed || !cameraReady
    }, [
        takePhotoAllowed,
        cameraReady,
    ])

    return (
        <div css={classes.root}>
            <div css={classes.webCamContainer}>
                <ReactWebcam
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/png"
                    height="100%"
                    width="100%"
                    ref={webcamRef}
                    onUserMedia={onUserMedia}
                />
            </div>
            {showLoader && (
                <div css={classes.loaderWrapper}>
                    <CircularProgress
                        thickness={2}
                        css={classes.loader}
                    />
                </div>
            )}
            <div css={classes.buttonContainer}>
                <div
                    onClick={onBackClick}
                    css={classes.backBtn}
                    aria-hidden="true"
                >
                    {t('BACK')}
                </div>
                <div
                    css={classes.takePhotoBtnCnt({
                        disabled: cameraButtonDisabled,
                    })}
                >
                    <div
                        onClick={handleCaptureClick}
                        css={classes.takePhotoBtn({
                            disabled: cameraButtonDisabled,
                        })}
                        data-key="capture"
                        aria-hidden="true"
                    />
                </div>

                <div css={classes.proceedBtnCn}>
                    { proceedAllowed && (
                        <div
                            css={classes.proceedBtn}
                            onClick={onProceedClick}
                            id="PROCESS_BUTTON"
                            aria-hidden="true"
                        >
                            {t('PROCEED_X_PHOTOS', {
                                photoNumber: photos.length,
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default WebCam
