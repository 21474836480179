import React from 'react'
import CloseIcon from '@src/assets/close_rounded.svg'
import {
    PhotoType,
} from '@src/context/PhotosContext/PhotosContext'
import classes from './FullScreen.style'

const FullScreen = ({
    photo,
    closeFullScreen,
}: {
    photo: PhotoType,
    closeFullScreen: () => void
}) => {
    return (
        <div
            css={classes.fullScreenView}
            style={{
                background: `url(${photo.base64}) no-repeat`,
                objectFit: 'contain',
            }}
        >
            <div
                role="button"
                tabIndex={0}
                css={classes.close}
                onClick={closeFullScreen}
                onKeyDown={closeFullScreen}
            >
                <img
                    src={CloseIcon}
                    alt="Close Full Screen"
                />
            </div>
        </div>
    )
}

export default FullScreen
