import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import isNumber from 'lodash/isNumber'

import APP_URLS from '@src/constants/AppUrls'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'
import Loading from '@src/shared-components/Loading/Loading'
import useConfirmGatewayInstallation from '@src/hooks/useConfirnGatewayInstallation'
import ConfirmDialog from '@src/shared-components/ConfirmDialog'
import useT from '@src/hooks/useT/useT'
import Carousel from '@src/shared-components/Carousel'

import FullScreen from '@src/shared-components/FullScreen'
import ErrorPage from '../Error/ErrorPage'
import TakePhotoOverviewPage from './components/TakePhotoOverviewPage'

function TakePhotoOverview(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        photos,
        removePhoto,
    } = usePhotoContext()

    const [
        fullScreenViewIndex,
        setFullScreenViewIndex,
    ] = useState<number | undefined>(undefined)
    const [
        showRemoveConfirmIndex,
        setShowRemoveConfirmIndex,
    ] = useState<number>()
    const [
        carouselIndex,
        setCarouselIndex,
    ] = useState(0)

    const navigate = useNavigate()
    const onSuccess = useCallback(() => {
        navigate(APP_URLS.summaryPage)
    }, [navigate])
    const {
        error,
        isLoading,
        saveAndConfirm,
        resetErrors,
    } = useConfirmGatewayInstallation(onSuccess)

    const handleNextClick = useCallback(() => {
        navigate(APP_URLS.takePhoto)
    }, [navigate])

    const handleContinueClick = useCallback(() => {
        saveAndConfirm(photos)
    }, [
        photos,
        saveAndConfirm,
    ])

    const onAfterError = useCallback(() => {
        resetErrors()
    }, [resetErrors])

    const onRemoveHandle = useCallback((i: number) => {
        setShowRemoveConfirmIndex(i)
    }, [])

    const onConfirmRemove = useCallback((remove: boolean) => {
        if (remove && isNumber(showRemoveConfirmIndex)) {
            removePhoto(showRemoveConfirmIndex)
        }
        setShowRemoveConfirmIndex(undefined)
    }, [
        removePhoto,
        showRemoveConfirmIndex,
    ])

    const allowedTakePhoto = useMemo(() => {
        return photos.length < 5
    }, [photos.length])

    const allowedContinue = useMemo(() => {
        return photos.length >= 3
    }, [photos])

    if (error) {
        return (
            <ErrorPage
                error={error}
                onBackClick={onAfterError}
            />
        )
    }

    if (!photos.length) {
        navigate(APP_URLS.takePhotoOverviewEmpty)
    }

    const fullScreenPhoto = isNumber(fullScreenViewIndex) && photos?.[fullScreenViewIndex]

    return fullScreenPhoto ? (
        <FullScreen
            photo={fullScreenPhoto}
            closeFullScreen={() => {
                setFullScreenViewIndex(undefined)
            }}
        />
    ) : (
        <>
            <Loading isLoading={isLoading}>
                <TakePhotoOverviewPage
                    onTakePhotoClick={handleNextClick}
                    allowedContinue={allowedContinue}
                    onContinueClick={handleContinueClick}
                    allowedTakePhoto={allowedTakePhoto}
                >
                    <Carousel
                        photos={photos}
                        removePhoto={onRemoveHandle}
                        setFullScreen={setFullScreenViewIndex}
                        index={carouselIndex}
                        setIndex={setCarouselIndex}
                    />
                </TakePhotoOverviewPage>
            </Loading>
            <ConfirmDialog
                dialogTitle={t('CONFIRM_DELETE') as string}
                open={isNumber(showRemoveConfirmIndex)}
                dialogContent={t('CONFIRM_DELETE_MESSAGE') as string}
                handleClose={onConfirmRemove}
                negativeLabel={t('CANCEL') as string}
                positiveLabel={t('DELETE') as string}
            />
        </>
    )
}

export default TakePhotoOverview
