import {
    css,
} from '@emotion/react'

const classes = {
    previewImg: css`
        display: flex;
        height: 100%;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        flex-grow: 1;
    `,
    container: css`
        width: 100%;
        height: 100%;
    `,
    icon: (isRight = false) => {
        return [
            css`
            position: absolute;
            top: 15px;
            left: 15px;
        `,
            isRight && css`
            left: unset;
            right: 15px;`,
        ]
    },
    fullScreenView: css`
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0,
        left: 0,
    `,
}

export default classes
