import React from 'react'
import {
    useParams,
} from 'react-router-dom'

import Loading from '@src/shared-components/Loading/Loading'
import useSetup from '@src/hooks/useSetup/useSetup'
import useGetGatewayById from '@src/api/hooks/useGetGatewayById'
import useGatewayScanned from '@src/hooks/useGatewayScanned'

const GatewayReceivedByUrl = () => {
    const {
        gateway = '',
    } = useParams()
    const [
        _state,
        setState,
    ] = useSetup()

    const {
        data: gatewayByIdData,
        isLoading: gatewayByLoading,
        error: gatewayByIdError,
    } = useGetGatewayById(gateway, {
        enabled: !!gateway,
        retry: false,
    })

    useGatewayScanned({
        gatewayByIdData,
        gateway,
        gatewayByIdError,
        setState,
    })

    return (
        <Loading isLoading={gatewayByLoading} />
    )
}

export default GatewayReceivedByUrl
