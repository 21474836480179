import React, {
    useMemo,
} from 'react'
import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom'
import ConfirmPage from '@src/pages/Confirm/ConfirmPage'
import TakePhotoPage from '../../pages/TakePhoto'
import TermsPage from '../../pages/Terms'
import ScanQRPage from '../../pages/ScanQR'
import GatewayDetectedPage from '../../pages/GatewayDetected/GatewayDetectedPage'
import AccessDeniedPage from '../../pages/AccessDenied/AccessDeniedPage'
import SummaryPage from '../../pages/Summary/SummaryPage'
import APP_URLS from '../../constants/AppUrls'
import ErrorPage from '../../pages/Error/ErrorPage'
import ScanQRErrorPage from '../../pages/ScanQRError'
import GatewayReceivedByUrl from '../../pages/GatewayDetected/GatewayRecievedByUrl'
import InstallGateway from '../../pages/InstallGateway'
import TakePhotoOverview, {
    TakePhotoOverviewEmpty,
} from '../../pages/TakePhotoOverview'
import RedirectPage from './RedirectPage'

type CustomRoute = {
    path: string;
    element: JSX.Element,
    requireAcceptedTerms?: boolean
}
const getRoutes = (hasAcceptedTerms:boolean | undefined): CustomRoute[] => {
    return [
        {
            path: APP_URLS.accessDenied,
            element: <AccessDeniedPage />,
        },
        {
            path: APP_URLS.scanQRError,
            element: <ScanQRErrorPage />,
        },
        {
            path: APP_URLS.error,
            element: <ErrorPage />,
        },
        {
            path: APP_URLS.terms,
            // if user on terms page but already confirmed terms - redirect to ScanQRPage
            element: hasAcceptedTerms ? <Navigate to={APP_URLS.scanQR} />
                : <TermsPage />,
        },
        {
            path: APP_URLS.confirm,
            // if user on confirm page but already confirmed terms - redirect to ScanQRPage
            element: hasAcceptedTerms ? <Navigate to={APP_URLS.scanQR} />
                : <ConfirmPage />,
        },
        {
            path: APP_URLS.scanQR,
            element: <ScanQRPage />,
            requireAcceptedTerms: true,
        },
        {
            path: APP_URLS.gatewayDetected,
            element: <GatewayDetectedPage />,
            requireAcceptedTerms: true,
        },
        {
            path: APP_URLS.installGateway,
            element: <InstallGateway />,
            requireAcceptedTerms: true,
        },
        {
            path: APP_URLS.takePhoto,
            element: <TakePhotoPage />,
            requireAcceptedTerms: true,
        },
        {
            path: APP_URLS.summaryPage,
            element: <SummaryPage />,
            requireAcceptedTerms: true,
        },

        {
            path: '/',
            element: hasAcceptedTerms ? <Navigate to={APP_URLS.scanQR} />
                : <Navigate to={APP_URLS.terms} />,
        },
        {
            path: APP_URLS.gatewayDetectedWithGateway,
            element: <GatewayReceivedByUrl />,
            requireAcceptedTerms: true,
        },
        {
            path: APP_URLS.takePhotoOverviewEmpty,
            element: <TakePhotoOverviewEmpty />,
            requireAcceptedTerms: true,
        },
        {
            path: APP_URLS.takePhotoOverview,
            element: <TakePhotoOverview />,
            requireAcceptedTerms: true,
        },
    ]
}

type Props = {
    hasAcceptedTerms: boolean | undefined
}
function RenderRoutes({
    hasAcceptedTerms,
} : Readonly<Props>) {
    const routes = useMemo(() => {
        return getRoutes(hasAcceptedTerms)
    }, [hasAcceptedTerms])

    const renderProtectedRoute = (route: CustomRoute): JSX.Element => {
        const Element = route.element

        if (!hasAcceptedTerms && route.requireAcceptedTerms) {
            // If route requires accepted terms - then redirect to TermsPage
            return <TermsPage />
        }

        return Element
    }

    return (
        <Routes>
            <Route
                path="redirect"
                element={<RedirectPage />}
            />
            {routes.map((route) => {
                return (
                    <Route
                        key={route.path}
                        element={renderProtectedRoute(route)}
                        path={route.path}
                    />
                )
            })}
        </Routes>
    )
}

export default RenderRoutes
